import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Screens/LandingPage/Home";
import Science from "../Screens/Science/Science";
import AboutNextImmune from "../Screens/AboutNextImmune/AboutNextImmune";
import ContactUs from "../Screens/ContactUs/ContactUs";
import Team from "../Screens/Team/Team";
import PrivacyPolicy from "../Screens/PrivacyPolicy/PrivacyPolicy";
import Disclaimer from "../Screens/Disclaimer/Disclaimer";
import News from "../Screens/News/News";
import ErrorPage from "../Screens/ErrorPage/ErrorPage";

import Layout from "../Layout/Layout";
import NXITherapeuticsExtendsPreSeedFinancing from "../Screens/News/NXITherapeuticsExtendsPreSeedFinancing";
class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route exact path={`/science`} component={Science} />
          <Route
            exact
            path={`/about-nxitherapeutics`}
            component={AboutNextImmune}
          />
          <Route exact path={`/contact-us`} component={ContactUs} />
          <Route exact path={`/team`} component={Team} />
          <Route exact path={`/privacy-policy`} component={PrivacyPolicy} />
          <Route exact path={`/disclaimer`} component={Disclaimer} />
          <Route exact path={`/news`} component={News} />
          <Route
            exact
            path={`/nxi-therapeutics-extends-pre-seed-financing-to-chf-3.5-million-in-run-up-to-larger-seed-financing`}
            component={NXITherapeuticsExtendsPreSeedFinancing}
          />
          <Route path={`*`} component={ErrorPage} />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
