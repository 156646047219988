import { Button, Col, notification, Row } from "antd";
import React from "react";
import apiClient from "../../Actions/ApiClient";
import InputBox from "../../Components/InputBox/InputBox";
import TitleSection from "../../Components/TitleSection/TitleSection";
import "./ContactUs.scss";
import { bgImages } from "../../Util/data";

export default class ContactUs extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
    };
  }

  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };

  onClear = () => {
    this.setState({ name: "", email: "", phone: "", company: "", message: "" });
  };

  onContactUs = () => {
    const { name, email, phone, company, message } = this.state;
    if (name && email && message) {
      apiClient
        .post("contactus/add", {
          name,
          email,
          phone,
          company,
          message,
        })
        .then(({ data }) => {
          if (data && data.result) {
            notification.success({
              message: "Message sent",
              description: "Thank you! We will reach you shortly",
            });
            this.onClear();
          } else {
            notification.error({
              message: "Something went wrong",
              description: "Something went wrong",
            });
          }
        });
    } else {
      notification.error({
        message: "Fields missing",
        description: "Name, email and message required",
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <TitleSection sliderImages={bgImages.contact} titleName="Contact Us" />

        <section className="contact-details py-5">
          <div className="container mx-auto py-5">
            <Row align="center" className="pb-3" gutter={[24, 22]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
              >
                <h1 className="text-blue">We are here to assist you</h1>
                <p className="class-lightblue">
                  Feel free to contact us or drop a line here. Our support staff
                  will reach you very soon.
                </p>
                <div className="contact-card">
                  <h3 className="text-white">NXI Therapeutics</h3>
                  <ul className="text-white">
                    <li>
                      <span className="d-block">Address:</span> Spitalstrasse 41
                      4056 Basel, Switzerland
                    </li>
                    <li>
                      <span className="d-block">Email:</span>{" "}
                      contact@nxitherapeutics.com
                    </li>
                    {/* <li>
                      <span className="d-block">Tel:</span> 0041 61 2077110
                    </li> */}
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
              >
                <div className="contact-form">
                  <form>
                    <div className="form-field">
                      <InputBox
                        placeholder="Full Name"
                        type="text"
                        label="Full Name"
                        id="name"
                        onChangeText={this.onChangeText}
                        value={this.state.name}
                      />
                    </div>
                    <div className="form-field">
                      <InputBox
                        placeholder="Your email"
                        type="email"
                        label="Email Address"
                        id="email"
                        onChangeText={this.onChangeText}
                        value={this.state.email}
                      />
                    </div>
                    <div className="form-field">
                      <InputBox
                        optional={true}
                        placeholder="Your phone number"
                        type="text"
                        label="Phone Number"
                        id="phone"
                        onChangeText={this.onChangeText}
                        value={this.state.phone}
                      />
                    </div>
                    <div className="form-field">
                      <InputBox
                        optional={true}
                        type="text"
                        placeholder="Company Name"
                        label="Company Name"
                        id="company"
                        onChangeText={this.onChangeText}
                        value={this.state.company}
                      />
                    </div>
                    <div className="form-field">
                      <InputBox
                        textArea={true}
                        placeholder="Message.."
                        label="Message"
                        id="message"
                        onChangeText={this.onChangeText}
                        value={this.state.message}
                      />
                    </div>
                    <div className="form-field mt-3">
                      <Button
                        onClick={() => this.onContactUs()}
                        className="w-100"
                        type="primary"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1346.0888801803537!2d7.579813856357843!3d47.56432970146468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4791b90823f34db1%3A0x6514dd6a814f4e84!2sSpitalstrasse%2041%2C%204056%20Basel%2C%20Switzerland!5e0!3m2!1sen!2sin!4v1669293368314!5m2!1sen!2sin"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
        </div>
      </React.Fragment>
    );
  }
}
