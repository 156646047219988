import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import "./Home.scss";
import HomeSlider from "./HomeSlider/HomeSlider";
import TheProblem from "../../assets/images/the-prolems.svg";
import TheSolutions from "../../assets/images/the-solution.svg";
import InnoBooster from "../../assets/images/innoboosters/innobooster.png";
import baselaunch from "../../assets/images/innoboosters/baselaunch.png";
import innosuisse from "../../assets/images/innoboosters/innosuisse.png";
import propellingGrant from "../../assets/images/innoboosters/propelling-grant.png";
import venturelab from "../../assets/images/innoboosters/venturelab.png";
import universityofBasel from "../../assets/images/innoboosters/university-of-basel.png";
import Venturekick from "../../assets/images/innoboosters/venturekick.png";
import swisstranspalant from "../../assets/images/innoboosters/swisstranspalant.png";
import LatestNews from "./LatestNews";

export default class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* main slider starts */}
        <HomeSlider />

        {/* main slider ends */}

        <section className="about-nextimmmune section-spacing">
          <div className="container">
            <Row className="text-center pb-3" align="center">
              <Col
                xs={{ span: 22 }}
                sm={{ span: 20 }}
                md={{ span: 17 }}
                lg={{ span: 17 }}
              >
                <h2 className="font-weight-bold text-blue pb-2">
                  About NXI Therapeutics
                </h2>
                <h4 className="section-subtitle">
                  NXI Therapeutics is a privately held biotechnology company
                  (AG)
                </h4>
                <p>
                  NXI Therapeutics focuses on the research and development of a
                  new generation of{" "}
                  <span className="text-blue">immunosuppressive drugs</span> for{" "}
                  <span className="text-blue">autoimmune diseases</span> and{" "}
                  <span className="text-blue">Organ Transplantation</span> -
                  with objectives of high efficacy, good safety without adverse
                  effects as known from conventional immunosuppressants.
                </p>
                <Link
                  to="/about-nxitherapeutics"
                  className="btn read-more btn-primary text-uppercase mt-3"
                >
                  know more <i className="flaticon-arrow-pointing-to-right" />
                </Link>
              </Col>
            </Row>
          </div>
        </section>

        {/* the problem */}
        <section className="the-problem bg-light-blue section-spacing">
          <div className="container">
            <Row className="pb-3" gutter={[12, 22]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 10 }}
                className="center-contents"
              >
                <div className="middle-content">
                  <h4 className="section-title font-weight-bold text-blue pb-sm-2 pb-lg-3">
                    The Problem
                  </h4>
                  <h2 className="section-subtitle text-darkblue pb-sm-2 pb-lg-2">
                    Conventional immunosuppression is non-selective and
                    increases infections and tumors
                  </h2>
                  <p>
                    A proper functioning immune system is essential to protect
                    against invading pathogens and to prevent the occurrence of
                    cancers. In autoimmune diseases (attack of own cells) or
                    following organ transplantation where allograft acceptance
                    is desired, suppression of the immune system is indicated.
                  </p>
                  <Link
                    to={"/about-nxitherapeutics"}
                    className="text-blue read-more "
                  >
                    Read More <i className="flaticon-arrow-pointing-to-right" />
                  </Link>
                </div>
              </Col>
              <Col
                className="text-center"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 14 }}
              >
                <h4>Non-selective immunosuppression</h4>
                <p className="text-darkblue">
                  Steroids, calcineurin blockers, anti-metabolites
                </p>
                <img
                  className="w-75"
                  src={TheProblem}
                  alt="Non-selective immunosuppression"
                />
                <p className="pt-sm-2 pt-lg-3 text-darkblue">
                  all the immune cells are affected.
                </p>
              </Col>
            </Row>
          </div>
        </section>

        {/* the solution */}
        <section className="the-solution section-spacing">
          <div className="container ">
            <Row className="pb-3" gutter={[12, 22]} align="middle">
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 10, order: 2 }}
                className="center-contents"
              >
                <div className="middle-content">
                  <h4 className="section-title text-blue pb-sm-2 pb-lg-3">
                    The Solution
                  </h4>
                  <h2 className="section-subtitle text-darkblue pb-sm-2 pb-lg-2">
                    Selective and efficient immunosuppressive drugs without
                    complications
                  </h2>
                  <p>
                    For the treatment of autoimmune diseases and transplant
                    rejection, NXI Therapeutics is developing immunosuppressants
                    that target the biology of a protein named coronin 1.
                    Coronin 1 regulates those T cell immune responses, that play
                    a key role in graft rejection and autoimmunity, while
                    leaving the protective responses against infections,
                    vaccination and cancers intact.
                  </p>
                  <Link
                    to={"/about-nxitherapeutics"}
                    className="text-blue read-more "
                  >
                    Read More <i className="flaticon-arrow-pointing-to-right" />
                  </Link>
                </div>
              </Col>
              <Col
                className="text-center"
                xs={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 14, order: 1 }}
              >
                <h4>Selective immunosuppression</h4>
                <p className="text-darkblue">Coronin 1 depleting strategy</p>
                <img
                  className="w-75"
                  src={TheSolutions}
                  alt="Selective immunosuppression"
                />
                <p className="pt-sm-2 pt-lg-3 text-darkblue">
                  only T cells are affected.
                </p>
              </Col>
            </Row>
          </div>
        </section>

        <LatestNews />

        {/* the solution 
        <section className="intellectual-property  section-spacing">
          <div className="container">
            <Row className="pb-3" align="middle" gutter={[12, 22]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 20 }}
                lg={{ span: 20 }}
              >
                <Row className="pb-3" gutter={[12, 22]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <h2 className="section-title text-white pb-sm-2 pb-lg-3">
                      Intellectual <span className="d-block">Property</span>
                    </h2>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <p className="text-white">
                      NXI therapeutics has access to global exclusive rights to
                      two recently filed IPs (EP 22/199307.4 and EP 22/199306.6)
                      from University of Basel related to the coronin 1
                      technology
                    </p>
                    <p className="text-white pt-sm-2 pt-lg-3">
                      The novel assay platform and screen identified compound
                      patents are currently under preparation.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>*/}

        {/* Awards and prices */}
        <section className="awards section-spacing">
          <div className="container">
            <Row className="pb-3 text-center">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2 className="section-title text-blue pb-sm-2 pb-lg-3">
                  Awards and support
                </h2>
              </Col>
            </Row>
            <Row className="text-center" align="center">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 22 }}
                lg={{ span: 20 }}
              >
                <Row className="text-center" align="center" gutter={[22, 22]}>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                  >
                    <div className="client-logo">
                      <img
                        src={swisstranspalant}
                        alt="Swiss Transplantation Society"
                        title="Swiss Transplantation Society"
                      />
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                  >
                    <div className="client-logo">
                      <img
                        src={universityofBasel}
                        alt="universityofBasel"
                        title="university of Basel"
                      />
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                  >
                    <div className="client-logo">
                      <img
                        src={propellingGrant}
                        alt="propellingGrant"
                        title="Propelling Grant"
                      />
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                  >
                    <div className="client-logo">
                      <img
                        src={Venturekick}
                        alt="Venturekick"
                        title="Venture kick"
                      />
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                  >
                    <div className="client-logo">
                      <img
                        src={venturelab}
                        alt="venturelab"
                        title="venture lab"
                      />
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                  >
                    <div className="client-logo">
                      <img
                        src={innosuisse}
                        alt="innosuisse"
                        title="innosuisse"
                      />
                    </div>
                  </Col>

                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                  >
                    <div className="client-logo">
                      <img
                        src={baselaunch}
                        alt="baselaunch"
                        title="Baselaunch"
                      />
                    </div>
                  </Col>

                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                  >
                    <div className="client-logo">
                      <img
                        src={InnoBooster}
                        alt="InnoBooster"
                        title="InnoBooster"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
