import React from "react";
import { Row, Col } from "antd";
import "./AboutNextImmune.scss";
import FunFacts1 from "../../assets/images/fun-facts/fun-facts-1.svg";
import FunFacts2 from "../../assets/images/fun-facts/fun-facts-2.svg";
import FunFacts3 from "../../assets/images/fun-facts/fun-facts-3.svg";
import TitleSection from "../../Components/TitleSection/TitleSection";
import { bgImages } from "../../Util/data";
export default class AboutNextImmune extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          sliderImages={bgImages.about}
          titleName="About NXI Therapeutics"
        />

        <section className="about-nextimmmune section-spacing">
          <div className="container">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <p>
                  NXI Therapeutics is developing a novel revolutionary class of
                  allo- and auto-immune selective immunosuppressants. We focus
                  on their therapeutic application for autoimmune diseases and
                  pathologic immune activation following organ/stem cell
                  transplantation, where suppression of the immune system is
                  required.
                </p>
                <h3 className="text-blue">
                  Significant unmet need for safe immunosuppression in
                  autoimmune disorders and transplantation
                </h3>
                <p>
                  With over 100 different known autoimmune diseases and
                  affecting as many as 5% of the global population, autoimmune
                  diseases represent a major clinical problem. It is one of the
                  top 10 causes of death in women under 65.{" "}
                </p>
                <p>
                  As of 2017, the autoimmune therapeutic market was valued at
                  over US$109 billion and has been growing at over 5% CAGR in
                  the last five years.
                </p>
                <p>
                  Autoimmune diseases overlap with the transplant sector, where
                  more than 153,863 organ transplants were performed worldwide
                  in 2019 (source: Global Observatory on Donation and
                  Transplantation 2019).{" "}
                </p>
                <p>
                  The global organ transplant immunosuppressant drugs market was
                  estimated to be over US$5 billion in 2022. It is largely
                  dominated by the class of calcineurin inhibitors (Market
                  watch, April 19, 2022).
                </p>
              </Col>
            </Row>
          </div>
        </section>
        <section className="section-spacing bg-light-blue">
          <div className="container mx-auto">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2 className="text-blue">Our approach </h2>
                <p>
                  The majority of current treatments lead to a generalized
                  defective immune response due to over-suppression of the
                  immune function. This can cause susceptibility to
                  opportunistic infections, invasive cancers and numerous side
                  effects, thereby severely affecting patient survival and
                  quality of life.
                </p>
                <p>
                  We are developing modulators of the Coronin 1 signaling
                  pathway for the induction of highly selective
                  immunosuppression without affecting broader defences against
                  infection and disease. This represents a major clinical
                  improvement in the management of autoimmune conditions and
                  complications following organ transplantation.{" "}
                </p>
                <p>
                  NXI Therapeutics’s therapeutic approach is derived from over
                  15 years of research conducted by co-founder Rajesh
                  Jayachandran, MD PhD.
                </p>
                <p>NXI Therapeutics was previously known as NextImmune. </p>
              </Col>
            </Row>
          </div>
        </section>
        <section className="section-spacing">
          <div className="container mx-auto">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2 className="text-blue">Our Vision </h2>
                <p>
                  NXI Therapeutics’ targeted therapy aims to treat autoimmune
                  disease whilst avoiding serious, long-term complications of
                  the current non-selective immunosuppression.
                </p>
                <p>
                  We are pioneering immunosuppressants that target Coronin 1,
                  which regulates the T cell immune responses that play a key
                  role in graft rejection and autoimmunity, while leaving the
                  protective responses against infections, vaccination and
                  cancers intact.
                </p>
                <p>
                  This holds great potential for more efficient and safer
                  treatments for autoimmune diseases as well as for
                  transplantation by realizing powerful targeted
                  immunosuppression that preserves immunocompetence.
                </p>
              </Col>
            </Row>
          </div>
        </section>
        <section className="section-spacing bg-light-blue">
          <div className="container mx-auto">
            <Row align="center" className="text-center pb-3" gutter={[24, 22]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                <div>
                  <img
                    src={FunFacts1}
                    alt="Pioneers with 20+ years of experience in coronin 1 biology"
                  />

                  <p className="text-black pb-2">
                    Pioneers with 20+ years of experience in coronin 1 biology
                  </p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                <div>
                  <img
                    src={FunFacts2}
                    alt="Committed for advancing IC1A development"
                  />

                  <p className="text-black pb-2">
                    Committed for advancing IC1A development
                  </p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                <div>
                  <img
                    src={FunFacts3}
                    alt="Close interaction between academia and industry"
                  />

                  <p className="text-black pb-2">
                    Close interaction between academia and industry
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
