import React from "react";
import { Row, Col, Image } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import "./News.scss";
import certificate1 from "../../assets/images/certificates/sts-award-jayachandran-rajesh-1st.jpg";
import { bgImages } from "../../Util/data";
import pressrelease from "../../assets/press-release/NXI-Therapeutics-Press-Release-Final-30Nov22.pdf";
import pressreleaseJune2024 from "../../assets/press-release/NXI-Therapeutics-Press-Release-18-June-2024.pdf";

const NewsData2024 = [
  {
    id: 1,
    date: "18 June 2024",
    location: "Basel, Switzerland",
    description:
      "NXI Therapeutics Extends Pre-Seed Financing to CHF 3.5 Million in Run-up to Larger Seed Financing",
    link: [pressreleaseJune2024],
  },
];
const NewsData2022 = [
  {
    id: 0,
    date: "30 Nov 2022",
    location: "NXI Therapeutics Press Release",
    description:
      "NXI Therapeutics Expands Management Team and Board Ahead of Next Growth Stage",
    link: [pressrelease],
  },
  {
    id: 1,
    date: "27 June 2022",
    location: "Basel Area",
    description: "NXI Therapeutics to benefit from Venture Kick financing.",
    link: "https://baselarea.swiss/blog-post/nextimmune-to-benefit-from-venture-kick-financing/",
  },
  {
    id: 2,
    date: "27 June 2022",
    location: "Basel Area",
    description: "Venture Kick promotes NXI Therapeutics",
    link: "https://baselarea.swiss/de/blog-post/venture-kick-fordert-nextimmune/",
  },
  {
    id: 3,
    date: "12 May 2022",
    location: "GRF InnoBooster Award",
    description:
      "NXI Therapeutics wins the coveted InnoBooster award 2022 from the Gebert Ruef Foundation.",
    link: "https://www.linkedin.com/posts/grstiftung_innobooster-winners-ii2022-activity-6930434706853257216-2EW4/?utm_source=linkedin_share&utm_medium=member_desktop_web",
  },
  {
    id: 4,
    date: "18 May 2022",
    location: "Startupticker",
    description:
      "NXI Therapeutics clears the VentureKick Stage III and becomes eligible for KickFund program.",
    link: "",
  },
];
export default class News extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection sliderImages={bgImages.news} titleName="News" />
        <section className="py-3">
          <div className="container mx-auto py-5">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 22 }}
                sm={{ span: 20 }}
                md={{ span: 17 }}
                lg={{ span: 17 }}
              >
                <h2 className="section-title text-blue pb-2">2024</h2>

                {NewsData2024.map((news, index) => (
                  <div className="news p-4 mb-3" data-index={index}>
                    <div className="details">
                      <span className="d-block text-black">{news.date}</span>
                      <span className="text-blue">{news.location}</span>
                      <p>{news.description}</p>
                      <a
                        href={`${news.link}`}
                        target="_blank"
                        rel="noreferrer"
                        class="text-blue"
                      >
                        Know More <i class="flaticon-arrow-pointing-to-right" />
                      </a>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </div>
        </section>
        <section className="py-3 bg-light-blue">
          <div className="container mx-auto py-5">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 22 }}
                sm={{ span: 20 }}
                md={{ span: 17 }}
                lg={{ span: 17 }}
              >
                <h2 className="section-title text-blue pb-2">2022</h2>

                {NewsData2022.map((news, index) => (
                  <div className="news p-4 mb-3" data-index={index}>
                    <div className="details">
                      <span className="d-block text-black">{news.date}</span>
                      <span className="text-blue">{news.location}</span>
                      <p>{news.description}</p>
                      <a
                        href={`${news.link}`}
                        target="_blank"
                        rel="noreferrer"
                        class="text-blue"
                      >
                        Know More <i class="flaticon-arrow-pointing-to-right" />
                      </a>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </div>
        </section>
        <section className="py-3 ">
          <div className="container mx-auto py-5">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 22 }}
                sm={{ span: 20 }}
                md={{ span: 17 }}
                lg={{ span: 17 }}
              >
                <h2 className="section-title text-blue pb-2">2021</h2>

                <div className="news p-4 mb-3">
                  <span className="d-block text-black">23 June 2021</span>
                  <span className="text-blue">baselaunch</span>
                  <p>
                    NXI Therapeutics selected as one of the portfolio companies
                    under the highly competetive BaseLaunch program
                  </p>
                  <a
                    href="https://baselaunch.ch/2021/06/23/expansion-of-baselaunch-portfolio-of-companies-2021/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue"
                  >
                    Know More <i className="flaticon-arrow-pointing-to-right" />
                  </a>
                </div>
                <div className="news p-4 mb-3">
                  <span className="d-block text-black">08 June 2021</span>
                  <span className="text-blue">Startupticker</span>
                  <p>
                    NXI Therapeutics selected under the highly competetive
                    Venture labs program
                  </p>
                  <a
                    href="https://www.venturelab.swiss/NextImmune"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue"
                  >
                    Know More <i className="flaticon-arrow-pointing-to-right" />
                  </a>
                </div>
                <div className="news p-4 mb-3">
                  <span className="d-block text-black">14 July 2021</span>
                  <span className="text-blue">venturelab</span>
                  <p>
                    The Swiss National Startup Team welcomes NXI Therapeutics as
                    one of its new members under Venture Leaders Biotech 2021. A
                    panel of experts selected NXI Therapeutics as one of the 10
                    Swiss startups for the first Venture Leaders program
                    dedicated explicitly to the biotech sector. Venture Leaders
                    Biotech 2021 is co-organized by Venturelab and Swissnex and
                    supported by EPF Lausanne, ETH Zurich, VISCHER, and the
                    Canton of Zurich. 
                  </p>
                  <a
                    href="https://www.venturelab.swiss/Venture-Leaders-Biotech-2021-10-new-Swiss-National-Startup-Team-members-to-showcase-Swiss-biotech-innovation-on-the-global-stage"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue"
                  >
                    Know More <i className="flaticon-arrow-pointing-to-right" />
                  </a>
                </div>
                <div className="news p-4 mb-3">
                  <span className="d-block text-black">13 September 2021</span>
                  <span className="text-blue">venturelab</span>
                  <p>
                    NXI Therapeutics selected as one of the Top 10 Swiss
                    start-ups under the Venture Leaders Biotech 2021 for a
                    Road-show to be held at Boston, USA.
                  </p>
                  <a
                    href="https://www.venturelab.swiss/The-Venture-Leaders-Biotech-2021-kick-off-their-international-roadshow-at-the-Swiss-Biotech-Day"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue"
                  >
                    Know More <i className="flaticon-arrow-pointing-to-right" />
                  </a>
                </div>
                <div className="news p-4 mb-3">
                  <span className="d-block text-black">24 September 2021</span>
                  <span className="text-blue">
                    Venturelab interviews NXI Therapeutics CSO
                  </span>
                  <p>
                    Dr. Rajesh jayachandran MD. PhD, CSO and Co-founder of NXI
                    Therapeutics interviewed by Venturelab.  
                  </p>
                  <a
                    href="https://www.venturelab.swiss/Meet-NextImmune-cofounder-Rajesh-Jayachandran-and-find-out-how-he-advances-the-development-of-novel-immunosuppressants"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue"
                  >
                    Know More <i className="flaticon-arrow-pointing-to-right" />
                  </a>
                </div>
                <div className="news p-4 mb-3">
                  <span className="d-block text-black">30 September 2021</span>
                  <span className="text-blue">Sino-Swiss Pitchinar</span>
                  <p>
                    NXI Therapeutics selected as one of the new Swiss biotech
                    start-ups to pitch in front of chinese investors at the
                    SinoSwiss pichinar.
                  </p>
                  <a
                    href="https://www.venturelab.swiss/SinoSwiss-Pitchinar-Selected-Swiss-biotech-startups-pitch-to-Chinese-investors-and-enterprises"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue"
                  >
                    Know More <i className="flaticon-arrow-pointing-to-right" />
                  </a>
                </div>
                <div className="news p-4 mb-3">
                  <span className="d-block text-black">17 November 2020</span>
                  <span className="text-blue">Top 10 Swiss-start-ups 2021</span>
                  <p>
                    NXI Therapeutics pitches as one of the Top 10 Swiss
                    start-ups under the Venture Leaders Biotech 2021 Road-show
                    held at Boston, USA.
                  </p>
                  <a
                    href="https://www.venturelab.swiss/The-Venture-Leaders-Biotech-dive-into-the-Boston-ecosystem"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue"
                  >
                    Know More <i className="flaticon-arrow-pointing-to-right" />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="py-3 bg-light-blue">
          <div className="container mx-auto py-5">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 22 }}
                sm={{ span: 20 }}
                md={{ span: 17 }}
                lg={{ span: 17 }}
              >
                <h2 className="section-title text-blue pb-2">2020</h2>

                <div className="news p-4 mb-3">
                  <Row align="center">
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 15 }}
                      lg={{ span: 15 }}
                    >
                      <span className="d-block text-black">23 Jan 2020</span>
                      <span className="text-blue">University of Basel</span>
                      <p>
                        NXI Therapeutics's co-founder, Dr. med. Rajesh
                        jayachandran MD. PhD, recieves the prestigious 1st prize
                        from Swiss Transplanatation Society for his research
                        work on coronin 1 and alloselelctive immunosuppression.
                      </p>

                      <a
                        href="https://www.biozentrum.unibas.ch/news/detail/awards-for-biozentrum-junior-researchers"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue"
                      >
                        Know More{" "}
                        <i className="flaticon-arrow-pointing-to-right" />
                      </a>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 9 }}
                      lg={{ span: 9 }}
                      className="text-center"
                    >
                      <Image src={certificate1} width="100%" />
                      <a
                        href="https://swisstransplantationsociety.com/home/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Swiss transplantation society
                      </a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
