import React from "react";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import { bgImages } from "../../Util/data";
export default class Disclaimer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          sliderImages={bgImages.disclaimer}
          titleName="Disclaimer"
        />
        <section className="section-spacing">
          <div className="container">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 22 }}
                className="text-left"
              >
                <h2 className="text-blue">IMPRINT</h2>
                <p>This website is operated by:</p>
                <p>NXI Therapeutics</p>
                <p>Spitalstrasse 41 4056 Basel, Switzerland</p>

                <h3>LEGAL INFORMATION</h3>
                <p>
                  Please read this legal information carefully. By using this
                  website you accept this legal information in its entirety and
                  unchanged.
                </p>
                <h3>Purpose of this website</h3>
                <p>
                  NXI Therapeutics AG prepared the information on this website
                  exclusively for the purpose of providing information on NXI
                  Therapeutics AG, its subsidiaries (hereinafter collectively
                  "NXI Therapeutics") and partners, and on the services offered.{" "}
                </p>
                <h3>Limitations on liability</h3>
                <p>
                  Information, software, products, and services published on
                  this website may contain inaccuracies, calculation or spelling
                  errors. The information does not constitute a binding offer.
                  NXI Therapeutics’s liability shall be limited to proven direct
                  damages or losses caused by gross negligence or intent. To the
                  extent permissible by law, NXI Therapeutics excludes any
                  liability for slight negligence as well as any liability for
                  indirect or consequential damages or losses.
                </p>
                <p>
                  This website may contain links or references to other
                  websites, which are not operated by NXI Therapeutics. NXI
                  Therapeutics shall not be responsible for the content of these
                  websites and shall not be liable for damages or losses
                  resulting from the contents of these websites.
                </p>

                <h3>Copyright</h3>
                <p>
                  Text, images, videos, software, products, services, and other
                  information presented on this website are copyrighted and may
                  not be amended, copied, performed, licensed, published,
                  uploaded, sent or made available in any other way without the
                  prior written consent of NXI Therapeutics or the owner of the
                  respective right. The foregoing shall not, however, limit the
                  user’s right of use for his or her personal use, subject to
                  all copyrights and neighboring rights.
                </p>

                <h3>Other</h3>
                <p>
                  NXI Therapeutics reserves the right to amend this legal
                  information as well as any of the documents, information, or
                  services made available on this website, at any time. If any
                  amendments are made, these shall enter into force and effect
                  upon their publication on this website. If you continue using
                  this website, you accept the respective amendments.
                </p>

                <p>
                  This legal information shall be governed exclusively by Swiss
                  law, to the exclusion of its conflict of laws rules and the
                  provisions of the UN Convention on Contracts for the
                  International Sale of Goods (CISG). The courts at the domicile
                  of NXI Therapeutics AG shall have exclusive jurisdiction.
                  Alternatively, NXI Therapeutics shall be entitled to prosecute
                  the user at his or her domicile.
                </p>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
