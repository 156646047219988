import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import "./HomeSlider.scss";
import { Row, Col } from "antd";
import mainvideo from "../../../assets/videos/video-1.mp4";
import posterImg from "../../../assets/images/main-slider/nextimmune.jpg";
class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="nextimmune-slider">
          <div className="slider-content ">
            <div className=" item">
              <video
                autoPlay
                loop
                muted
                playsInline
                poster={posterImg}
                className="back-video"
              >
                <source src={mainvideo} type="video/mp4" />
              </video>
              <div className="center-contents w-100">
                <div className=" middle-content ">
                  <div className="container mx-auto ">
                    <div className="slider-caption">
                      <Row align="center">
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        >
                          <h1 className="text-white font-weight-bold mt-5 mb-3 mb-lg-3">
                            Creating tomorrow’s
                            <span className="d-block">immunotherapies</span>
                          </h1>
                          <h2 className="text-white mb-3 mb-lg-2">
                            The beginning of a new era of specificity and safety
                            in immunosuppressive therapy
                          </h2>
                          <p className="text-white mt-3">
                            NXI Therapeutics is developing next generation
                            immunosuppressive drugs for autoimmune diseases and
                            organ transplantation, with the objectives of high
                            efficacy and safety without the adverse effects of
                            conventional immunosuppressants.{" "}
                          </p>
                          <Link
                            to="/about-nxitherapeutics"
                            className="text-white text-uppercase read-more"
                          >
                            Find out more
                            <i className="flaticon-arrow-pointing-to-right" />
                          </Link>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                        ></Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Slider Item ends--> */}
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
