import React from "react";
import { Row, Col } from "antd";
import "./Science.scss";
import Scicence1 from "../../assets/images/science/1.svg";
import Scicence2 from "../../assets/images/science/2.svg";
import Scicence3 from "../../assets/images/science/3.svg";
import TitleSection from "../../Components/TitleSection/TitleSection";
import { bgImages } from "../../Util/data";
export default class Science extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection sliderImages={bgImages.science} titleName="Science" />

        <section className="section-spacing">
          <div className="container">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 18 }}
                className="text-center"
              >
                <h2 className="text-blue">The problem</h2>
                <h3>
                  Conventional Immunosuppression is non-selective and increases
                  infections and tumours{" "}
                </h3>
                <p>
                  A proper functioning immune system is essential to protect
                  against invading pathogens and to prevent the occurrence of
                  cancers. In autoimmune diseases (the attack of own cells) or
                  following organ transplantation where allograft acceptance is
                  desired, suppression of the immune system is required.
                </p>
                <p>
                  The majority of current treatment options lead to a
                  generalized defective immune response due to
                  blanket-suppression of the entire immune function.
                </p>
              </Col>
            </Row>
          </div>
        </section>

        <section className="section-spacing bg-light-blue">
          <div className="container">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 18 }}
                className="text-center"
              >
                <h2 className="text-blue">The solution</h2>
                <h3>
                  Selective and efficient immunosuppression and efficient
                  immunosuppressive drugs without complications
                </h3>
                <p>
                  NXI Therapeutics is pioneering the development of selective
                  immunosuppressants that target the Coronin 1 pathway, which
                  regulates the T cell immune responses that play a key role in
                  graft rejection and autoimmunity, while leaving the protective
                  responses against infections, vaccination and cancers intact.
                </p>
                <p>
                  Our research shows that Coronin 1 depletion should allow for
                  the long-term acceptance of genetically unrelated organ
                  transplants, prevent Graft vs Host Disease (GvHD), protect
                  from several autoimmune disorders, and at the same time allow
                  a normal life span without infections and occurrence of
                  cancer. Thus, modulating Coronin 1 signaling holds great
                  potential for more efficient and safer treatments for
                  autoimmune diseases, as well as for transplantation, by
                  realizing powerful targeted immunosuppression that preserves
                  immunocompetence.
                </p>
              </Col>
            </Row>
          </div>
        </section>

        <section className="our-science-covers section-spacing">
          <div className="container">
            <Row align="center" className="text-center pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 20 }}
                md={{ span: 17 }}
                lg={{ span: 17 }}
              >
                <h2 className="text-blue pb-2 mb-2">
                  Making alloselective immunosuppression possible
                </h2>
              </Col>
            </Row>
            <Row align="center" className="text-center pb-3" gutter={[24, 22]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <div className="card shape-2">
                  <p className="text-darkblue pb-2">No immunosuppression</p>
                  <img src={Scicence1} alt="No immunosuppression" />
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <div className="card color-2 shape-3">
                  <p className="text-darkblue pb-2">
                    Non-Selective Immunosuppression (Current drugs)
                  </p>
                  <img
                    src={Scicence2}
                    alt="Non-Selective Immunosuppression (Current drugs)"
                  />
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <div className="card">
                  <p className="text-darkblue pb-2">
                    Allo-Selective immunosuppression (Coronin-1 depletion)
                  </p>
                  <img
                    src={Scicence3}
                    alt="Allo-Selective immunosuppression (Coronin-1 depletion)"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
