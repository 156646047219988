import React, { Component } from "react";
import { Row, Col } from "antd";
import pressrelease from "../../assets/press-release/NXI-Therapeutics-Press-Release-Final-30Nov22.pdf";
import pressreleaseJune2024 from "../../assets/press-release/NXI-Therapeutics-Press-Release-18-June-2024.pdf";

const NewsData = [
  {
    id: 1,
    date: "18 June 2024",
    location: "Basel, Switzerland",
    description:
      "NXI Therapeutics Extends Pre-Seed Financing to CHF 3.5 Million in Run-up to Larger Seed Financing",
    link: [pressreleaseJune2024],
  },
  {
    id: 0,
    date: "30 Nov 2022",
    location: "NXI Therapeutics Press Release",
    description:
      "NXI Therapeutics Expands Management Team and Board Ahead of Next Growth Stage",
    link: [pressrelease],
  },

  {
    id: 2,
    date: "27 June 2022",
    location: "Basel Area",
    description: "NXI Therapeutics to benefit from Venture Kick financing.",
    link: "https://baselarea.swiss/blog-post/nextimmune-to-benefit-from-venture-kick-financing/",
  },
  {
    id: 3,
    date: "27 June 2022",
    location: "Basel Area",
    description: "Venture Kick promotes NXI Therapeutics",
    link: "https://baselarea.swiss/de/blog-post/venture-kick-fordert-nextimmune/",
  },
];

export default class LatestNews extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="intellectual-property section-spacing latest-news">
          <div className="container">
            <h2 className="text-white text-center mb-4">Latest News</h2>
            <Row gutter={[12, 12]} className="text-left pb-3" align="center">
              {NewsData.map((news, index) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  data-index={index}
                >
                  <div className="news-list bg-white">
                    <div className="details">
                      <span>{news.date}</span>
                      <h3 className="text-blue text-uppercase">
                        {news.location}
                      </h3>
                      <p>{news.description}</p>
                      <a
                        href={`${news.link}`}
                        target="_blank"
                        rel="noreferrer"
                        class="text-blue"
                      >
                        Know More <i class="flaticon-arrow-pointing-to-right" />
                      </a>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
