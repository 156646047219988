import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Col, FloatButton } from "antd";
import "./Footer.scss";
import { UpOutlined } from "@ant-design/icons";
export default class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="footer py-5">
          <div className="container mx-auto">
            <Row gutter={[12, 22]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                <div className="info-links">
                  <h3>Quick Links</h3>
                  <ul>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/about-nxitherapeutics">About</Link>
                    </li>
                    <li>
                      <Link to="/news">News</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                <div className="info-links">
                  <h3>Information</h3>
                  <ul>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/disclaimer">Disclaimer</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                <div className="social-media">
                  <h3>Social Media</h3>
                  <ul className="mb-0 pl-0">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/nextimmune-ag/"
                        target="blank"
                      >
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    {/* <li>
                      <Link>
                        <i className="fa fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="fa fa-facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="fa fa-instagram" />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                <p className="text-white">
                  © {new Date().getFullYear()} Copyright NXI Therapeutics. All
                  Rights Reserved.
                </p>
              </Col>
            </Row>
            {/* <div className="bottom-footer pt-3 mt-2">
              <Row gutter={[12, 22]}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <p className="text-white">
                    © {new Date().getFullYear()} Copyright NXI Therapeutics. All
                    Rights Reserved.
                  </p>
                </Col>
              </Row>
            </div> */}
          </div>
        </div>
        <FloatButton.BackTop>
          <div className="back-to-top">
            <UpOutlined />
          </div>
        </FloatButton.BackTop>
      </React.Fragment>
    );
  }
}
