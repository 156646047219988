import React from "react";
import { Col } from "antd";
import "./Team.scss";
import Rajesh from "../../assets/images/team/leadership/dr-rajesh-jayachandran.jpg";
import Empty from "../../assets/images/team/leadership/empty.jpg";

const BoardDirector = [
  {
    id: 1,
    img: [Rajesh],
    name: "Dr. med. Rajesh Jayachandran, MD, PhD ",
    description:
      "Clinician and Immunologist. Swiss Transplantation Society award, Propelling award, Max Cloëtta fellowship, Pfizer award, Perumal award, SNF fellowship. Novartis biotechnology leadership camp.",
  },
  {
    id: 2,
    img: [Empty],
    name: "Mr. Filippo Oliveri",
    description:
      "Serial biotech entrepreneur with expertise in Medtech & Biopharma. Board member of Matterhorn Biosciences.",
  },
  {
    id: 3,
    img: [Empty],
    name: "Prof. Dr. Jean Pieters ",
    description:
      "Pioneer in coronin biology. Elected Member of the Royal Dutch Academy of Sciences. Pfizer prize, Eppendorf Young Investigator award, Friedrich Miescher award, Sanofi award.",
  },
];

// const BoardofDirectorsSettings = {
//   arrows: true,
//   dots: true,
//   slidesToShow: 2,
//   slidesToScroll: 2,
//   //   centerMode: true,
//   autoplay: true,
//   autoplaySpeed: 3000,
//   pauseOnFocus: true,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 2,
//         slidesToShow: 2,
//       },
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 1,
//         slidesToShow: 1,
//         arrows: false,
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//         slidesToShow: 1,
//         arrows: false,
//       },
//     },
//   ],
// };
export default class Team extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <Slider {...BoardofDirectorsSettings}> */}
        {BoardDirector.map((leader, index) => (
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            data-index={index}
          >
            <div className="list board ">
              <div className="card">
                <div className="image">
                  <img src={leader.img} alt={`${leader.name}`} />
                </div>
                <div className="details">
                  <h3>{leader.name}</h3>
                  <div className="position">{leader.position}</div>
                  <p>{leader.description}</p>
                </div>
              </div>
            </div>
          </Col>
        ))}
        {/* </Slider> */}
      </React.Fragment>
    );
  }
}
