import { message } from "antd";
import axios from "axios";
import AppConfig from "../config";

const apiClient = axios.create({
  baseURL: `${AppConfig.API_BASE_URL}/`,
});

apiClient.interceptors.request.use(
  (config) => {
    return {
      ...config,
      //   headers: {
      //     Authorization: config.headers.Authorization || `Bearer ${localStorage.getItem('user')}`
      //   }
    };
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async ({ response }) => {
    if (response) {
      const { data, status } = response;

      if (status === 401) {
        message.error(data.message);
        localStorage.removeItem("ACCOUNTING_USER");
        window.location.href = "/login";

        return response;
      }

      if (status >= 400 && status < 500) {
        if (data.errors && Object.keys(data.errors).length > 0) {
          errorsParser(data.errors);
        } else if (data.message) {
          message.error(data.message);
        }

        return Promise.resolve({ status, ...data });
      }

      if (status >= 500) {
        errorsParser(data.errors);

        return Promise.resolve({ status, ...data });
      }

      return Promise.resolve({ status, ...data });
    }
  }
);

const errorsParser = (errors) => {
  if (errors) {
    if (typeof errors === "string") {
      message.error(errors);
    } else {
      Object.values(errors).forEach((err) => {
        message.error(err);
      });
    }
  }
};

export default apiClient;
