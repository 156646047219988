import science1 from "../assets/images/science/science-1.jpg";
import science2 from "../assets/images/science/science-2.jpg";
import science3 from "../assets/images/science/science-3.jpg";
import science4 from "../assets/images/science/science-4.jpg";

import pipeline1 from "../assets/images/pipeline/pipeline-1.jpg";
import pipeline2 from "../assets/images/pipeline/pipeline-2.jpg";

import about1 from "../assets/images/about/about-1.jpg";
import about2 from "../assets/images/about/about-2.jpg";

import news1 from "../assets/images/news/news-1.jpg";
import news2 from "../assets/images/news/news-2.jpg";
import news3 from "../assets/images/news/news-3.jpg";

import team1 from "../assets/images/team/sliders/team-1.jpg";
import team2 from "../assets/images/team/sliders/team-2.jpg";
import team3 from "../assets/images/team/sliders/team-3.jpg";

export const bgImages = {
  science: [science1, science2, science3, science4],
  pipeline: [pipeline2, pipeline1],
  about: [about1, about2],
  news: [news1, news2, news3],
  team: [team1, team2, team3],
  contact: [team1, science2, team2, team3],
  ourMarket: [news1, pipeline2],
  financialInformation: [about1],
  disclaimer: [science2],
  error: [about1],
};
