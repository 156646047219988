import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import { bgImages } from "../../Util/data";
export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          sliderImages={bgImages.pipeline}
          titleName="Privacy Policy"
        />

        <section className="section-spacing">
          <div className="container">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 22 }}
                className="text-left"
              >
                <h2 className="text-blue">Privacy Policy</h2>
                <h3>NXI Therapeutics</h3>
                <h4>Name and contact details of the controller</h4>
                <p>
                  This data protection information applies to data processing
                  by:
                </p>
                <p>NXI Therapeutics</p>
                <p>Spitalstrasse 41 4056 Basel, Switzerland</p>
                <p>CEO: Ruben Herrendorff</p>
                <h3 className="text-blue">
                  Collection and storage of personal data as well as type and
                  purpose of their use when visiting the website
                </h3>
                <p>
                  When you visit our website nxitherapeutics.com, the browser on
                  your device automatically sends information to the server on
                  our website. This information is temporarily stored in a
                  so-called log file. The following information is recorded
                  without your intervention and stored until it is automatically
                  deleted:
                </p>
                <ul>
                  <li>IP address of the requesting computer,</li>
                  <li>date and time of access,</li>
                  <li>name and URL of the retrieved file,</li>
                  <li>website from which access is made (referrer URL),</li>
                  <li>
                    the browser used and, if applicable, the operating system of
                    your computer as well as the name of your access provider.
                  </li>
                </ul>
                <h4>
                  The mentioned data will be processed by us for the following
                  purposes:
                </h4>
                <ul>
                  <li>ensuring a smooth connection of the website,</li>
                  <li>ensure comfortable use of our website,</li>
                  <li>
                    evaluation of system security and stability as well as for
                    other administrative purposes.
                  </li>
                </ul>
                <p>
                  The legal basis for data processing is Art. 6 para. 1 sentence
                  1 lit. f General Data Protection Regulation - hereinafter
                  GDPR. Our legitimate interest follows from the purposes listed
                  above for data collection. Under no circumstances do we use
                  the data collected for the purpose of drawing conclusions
                  about you personally.
                </p>
                <h3 className="text-blue">Contacting us</h3>
                <p>
                  When contacting us (via{" "}
                  <Link to={"/contact-us"}> contact form</Link> or e-mail), the
                  user's details are processed for processing the contact
                  enquiry and its handling in accordance with Art. 6 para. 1
                  lit. b) GDPR.
                </p>
                <p>
                  User information can be stored in our Customer Relationship
                  Management System ("CRM System") or comparable systems.
                </p>
                <h3 className="text-blue">Passing on / of Data</h3>
                <p>
                  Your personal data will not be transmitted to third parties
                  for purposes other than those listed below.
                </p>
                <h4>
                  We will only pass on your personal data to third parties if:
                </h4>
                <ul>
                  <li>
                    you have given your express consent pursuant to Art. 6 para.
                    1 sentence 1 lit. a GDPR,
                  </li>
                  <li>
                    the disclosure pursuant to Art. 6 para. 1 sentence 1 f GDPR
                    is necessary to assert, exercise or defend legal claims and
                    there is no reason to assume that you have an overriding
                    interest worthy of protection in not disclosing your data,
                  </li>
                  <li>
                    in the event that a legal obligation exists for the transfer
                    pursuant to Art. 6 para. 1 sentence 1 lit. c GDPR, and
                  </li>
                  <li>
                    this is legally permissible and is necessary for the
                    processing of contractual relationships with you in
                    accordance with Art. 6 para. 1 sentence 1 lit. b GDPR.
                  </li>
                </ul>

                <h3 className="text-blue">Rights of the persons concerned</h3>
                <h4>You have the right:</h4>
                <ul>
                  <li>
                    to request information about your personal data processed by
                    us in accordance with Art. 15 GDPR. In particular, you may
                    request information about the purposes of processing , the
                    category of personal data, the categories of recipients to
                    whom your data have been or will be disclosed, the planned
                    storage period, the existence of a right to rectification,
                    deletion, restriction of processing or objection, the
                    existence of a right of appeal, the origin of your data, if
                    these have not been collected by us, and the existence of
                    automated decision-making including profiling and, if
                    applicable, meaningful information on their details;
                  </li>
                  <li>
                    to immediately request the correction of incorrect or
                    complete personal data stored by us in accordance with Art.
                    16 GDPR;
                  </li>
                  <li>
                    to request the deletion of your personal data stored by us
                    in accordance with Art. 17 GDPR , unless the processing is
                    necessary to exercise the right to freedom of expression and
                    information, to fulfil a legal obligation, for reasons of
                    public interest or to assert, exercise or defend legal
                    claims;
                  </li>
                  <li>
                    pursuant to Art. 18 GDPR, to restrict the processing of your
                    personal data if you dispute the accuracy of the data, if
                    the processing is unlawful but you refuse to delete the data
                    and we no longer need the data, but if you need it to
                    assert, exercise or defend legal claims or if you have filed
                    an objection to the processing pursuant to Art. 21 GDPR;
                  </li>
                  <li>
                    pursuant to Art. 20 GDPR, to receive your personal data that
                    you have provided to us in a structured, current and
                    machine-readable format or to request its transfer to
                    another person responsible;
                  </li>
                  <li>
                    in accordance with Art. 7 para. 3 GDPR, to revoke your
                    consent to us at any time. As a result, we are no longer
                    allowed to continue processing data based on this consent in
                    the future and to complain to a supervisory authority
                    pursuant to Art. 77 GDPR. As a rule, you can contact the
                    supervisory authority of your usual place of residence or
                    workplace or our office.{" "}
                  </li>
                </ul>
                <h3 className="text-blue">Right of objection</h3>
                <p>
                  If your personal data are processed on the basis of legitimate
                  interests pursuant to Art. 6 para. 1 sentence 1 lit. of GDPR,
                  you have the right to object to the processing of your
                  personal data pursuant to Art. 21 GDPR, provided that there
                  are reasons for this which arise from your particular
                  situation or the objection is directed against direct
                  advertising . In the latter case, you have a general right of
                  objection, which we will implement without specifying a
                  particular situation.
                </p>
                <p>
                  If you would like to make use of your right of withdrawal or
                  objection, simply send an e-mail to
                  contact@nxitherapeutics.com
                </p>
                <h3 className="text-blue">Data security</h3>
                <p>
                  We use the most common SSL (Secure Socket Layer) method in
                  connection with the highest level of encryption supported by
                  your browser. Usually this is a 256 bit encryption. If your
                  browser does not support 256-bit encryption, we use 128-bit v3
                  technology instead. Whether a single page of our website is
                  transmitted in encrypted form is indicated by the closed
                  display of the key or lock symbol in the lower status bar of
                  your browser.
                </p>
                <p>
                  We also use suitable technical and organisational security
                  measures to protect your data against accidental or
                  intentional manipulation, partial or complete loss,
                  destruction or unauthorised access by third parties. Our
                  security measures are continuously improved in line with
                  technological developments.
                </p>

                <h3 className="text-blue">
                  Up-to-dateness and amendment of this data protection
                  declaration.
                </h3>
                <p>
                  This data protection declaration is currently valid and has
                  the status as of July 2018.
                </p>
                <p>
                  Due to the further development of our website and offers above
                  or due to changed legal or official requirements, it may
                  become necessary to change this data protection declaration.
                </p>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
