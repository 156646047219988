import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import mainlogo from "../assets/images/nxi-therapeutics-logo.svg";

export let setHeader = false;
export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      openMenu: false,
    };
  }
  componentDidMount() {
    window.onscroll = function () {
      myFunction();
    };

    var header = document.getElementById("main-hearder");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    }
    //Onscroll sticky Header function ends here
  }

  render() {
    return (
      <React.Fragment>
        <div className="main-hearder" id="main-hearder">
          <div className="container">
            <div className="top-header">
              <div className="logo pr-3">
                <Link to="/">
                  <img
                    src={mainlogo}
                    alt="NXI Therapeutics | Creating tomorrow’s immunotherapies"
                  />
                  {/* <img
                    src={mainlogo}
                    alt="NXI Therapeutics | Creating tomorrow’s immunotherapies"
                  /> */}
                </Link>
              </div>
              <div
                className={
                  this.state.openMenu ? "navigations opened" : "navigations"
                }
              >
                <div className="center-header">
                  <ul>
                    <li>
                      <Link
                        exact
                        onClick={() => this.setState({ openMenu: false })}
                        to={"/about-nxitherapeutics"}
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => this.setState({ openMenu: false })}
                        to={"/science"}
                      >
                        Science
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => this.setState({ openMenu: false })}
                        to={"/team"}
                      >
                        Team
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => this.setState({ openMenu: false })}
                        to={"/news"}
                      >
                        News
                      </Link>
                    </li>
                    <li className="contact-responsive">
                      <Link
                        onClick={() => this.setState({ openMenu: false })}
                        to={"./contact-us"}
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                  <div className="contact-button">
                    <Link
                      to={"./contact-us"}
                      className="btn btn-primary text-uppercase"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>

              <div
                id="hamburger"
                className={this.state.openMenu ? "menuopened" : ""}
                onClick={() =>
                  this.setState({ openMenu: !this.state.openMenu })
                }
              />
            </div>

            {/* bottom header starts */}

            {/* bottom header ends */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
