import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import ErrorImage from "../../assets/images/error-page.svg";
import TitleSection from "../../Components/TitleSection/TitleSection";
import { bgImages } from "../../Util/data";
export default class ErrorPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          sliderImages={bgImages.error}
          titleName="Page Not Found!"
        />
        <section className="section-spacing" id="aboutus">
          <div className="container">
            <Row gutter={[22, 22]} align="center">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="px-lg-3">
                  <h1 className="main-heading text-blue mb-3">
                    Sorry! Page Not Found!
                  </h1>
                  <p>
                    Oops! The page which you are looking for does not exist.
                    Please return to the homepage.
                  </p>
                  <Link
                    to="/"
                    className="btn btn-primary btn-lg text-capitalize"
                  >
                    Back to Home Page
                  </Link>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="px-lg-3 text-center">
                  <img src={ErrorImage} alt="WW INFRA" className="w-100 mb-4" />
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
