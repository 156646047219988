import React from "react";
import TitleSection from "../../Components/TitleSection/TitleSection";
import { bgImages } from "../../Util/data";

export default function NXITherapeuticsExtendsPreSeedFinancing() {
  return (
    <>
      <TitleSection
        sliderImages={bgImages.science}
        titleName={"NXI Therapeutics Press Release"}
      />
      <section className=" section-spacing">
        <div className="container">
          <span className="d-block">Press Release_18 June 2024</span>
          <h1>
            NXI Therapeutics Extends Pre-Seed Financing to CHF 3.5 Million in
            Run-up to Larger Seed Financing
          </h1>
          <p>
            Kickfund joins group of investors including JFG Life Sciences
            Foundation, BaseLaunch, and Venture Kick
          </p>

          <p>
            <strong>18 June 2024, Basel, Switzerland</strong> – NXI Therapeutics
            AG, developer of tomorrow’s disruptive immunotherapies by coronin 1
            modulation, today announces the extension of a pre-Seed financing
            totalling to 3.5 million CHF. This financing extension comes in the
            run-up to a larger Seed financing that the company is currently
            raising. Kickfund joins a group of investors including lead investor
            JFG Life Sciences Foundation, BaseLaunch and Venture Kick. NXI has
            also been supported by an InnoBooster grant from the Gebert Rüf
            Foundation and a Propelling grant from the University of Basel.
          </p>

          <p>
            Further, Patrick Burgermeister, CEO of the JFG Life Sciences
            Foundation, will join the company’s Board of Directors. He has a
            highly relevant background with prior positions in venture capital
            (BioMedPartners), pharma (Novartis) and banking (Zürcher
            Kantonalbank). He holds Master’s degrees in Molecular Biology from
            the University of Basel and in Business Administration from the
            University of St. Gallen (HSG).
          </p>

          <h3>About coronin 1 modulation</h3>
          <p>
            Coronin 1 depletion in preclinical models allows long-term
            acceptance of genetically unrelated organ transplants, prevents
            Graft versus Host Disease (GvHD), protects from several autoimmune
            disorders and at the same time allows to maintain a normal life span
            without infections and emergence of cancer, a frequent complication
            of contemporary immunosuppressants. Thus, modulating the coronin 1
            pathway holds great potential for more effective and safer
            treatments for autoimmune diseases, as well as for transplantation
            medicine.
          </p>
          <h3>About NXI Therapeutics</h3>
          <p>
            NXI Therapeutics is a spin-off from the Biozentrum, University of
            Basel, Switzerland founded by Prof. Jean Pieters and CSO Dr. med.
            Rajesh Jayachandran. The company focuses on clinical applications in
            T cell-mediated autoimmune conditions and is committed to creating
            tomorrow’s disruptive immunotherapies through modulation of coronin
            1 in immune cells. The uniqueness of the thereby induced
            immunomodulation is its selectivity for auto- and allo-immune
            responses, whereas immune responses against infections and cancer
            are maintained.
          </p>
          <h3>About Kickfund</h3>
          <p>
            Kickfund aims to invest in Swiss pre-seed and seed stage technology
            startups that have won the Venture Kick competition – an established
            startup competition and accelerator that has been supporting Swiss
            early-stage companies for over 15 years. Kickfund Ventures Fund I
            SCSp is a closedend fund domiciled in the Grand Duchy of Luxembourg.
          </p>
          <p>
            The partnership was initiated and established by a group of people
            who are strong supporters of the startup and innovation ecosystem in
            Switzerland.
          </p>
          <p>
            Kickfund AG is a FINMA-licensed portfolio manager acting as
            investment manager of the Fund.
          </p>
          <h3>About BaseLaunch</h3>
          <p>
            BaseLaunch is the Basel-based incubator and accelerator that helps
            scientists and entrepreneurs launch exceptional biotech companies.
            It serves as a growth platform for early-stage ventures developing
            cutting-edge therapeutics. BaseLaunch helps build companies from
            inception through to Series A funding, providing financing and
            supporting all aspects of company creation and growth. Since 2018,
            the portfolio of 24 ventures have raised in total over US$ 600
            millions in venture financing. BaseLaunch is operationally run and
            financed by Basel Area Business & Innovation and partners with
            leading global pharma and biotech companies and a venture fund.
          </p>

          <h3>For further information</h3>

          <p>Dr. Ruben Herrendorff</p>
          <p>
            <a href="mailto:rherrendorff@nxitherapeutics.com">
              rherrendorff@nxitherapeutics.com
            </a>
          </p>
        </div>
      </section>
    </>
  );
}
