import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import "./Team.scss";
import { bgImages } from "../../Util/data";
import LeadershipTeam from "./LeadershipTeam";
import BoardofDirectors from "./BoardofDirectors";

export default class Team extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          sliderImages={bgImages.team}
          titleName="Our experienced team"
        />

        <section className="section-spacing team-details">
          <div className="container mx-auto">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2 className="text-blue text-center mb-3">Leadership Team </h2>
              </Col>
            </Row>
            <Row gutter={[12, 12]} align="center" className="pb-3">
              <LeadershipTeam />
            </Row>
          </div>
        </section>

        <section className="section-spacing bg-light-blue team-details">
          <div className="container mx-auto">
            <Row align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2 className="text-blue text-center">Board of Directors </h2>
              </Col>
            </Row>
            <Row gutter={[12, 12]} className="pb-3">
              <BoardofDirectors />
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
