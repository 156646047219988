import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./TitleSection.scss";
import scrollDown from "../../assets/images/mouse-icon.svg";
import Slider from "react-slick";

const TitleSliderSettings = {
  arrows: false,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  fade: true,
  cssEase: "ease-in-out",
  touchThreshold: 100,
};
class TitleSection extends Component {
  render() {
    return (
      <React.Fragment>
        <section className={`top-banner text-center ${this.props.className}`}>
          <Slider {...TitleSliderSettings}>
            {this.props.sliderImages.map((image, i) => (
              <div className="list" key={i}>
                <img src={image} className="zoom-in-effect" alt="" />
              </div>
            ))}
          </Slider>
          <div className="center-contents w-100">
            <div className="middle-content container">
              <div className="caption">
                <h1 className="text-white">{this.props.titleName}</h1>
              </div>
            </div>
          </div>
          <div className="move-down">
            <img src={scrollDown} alt="Move to another section" />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(TitleSection);
