import React from "react";
import { Col } from "antd";
import "./Team.scss";
import Ruben from "../../assets/images/team/leadership/dr-ruben-herrendorff.jpg";
import Rajesh from "../../assets/images/team/leadership/dr-rajesh-jayachandran.jpg";
import Zoltan from "../../assets/images/team/leadership/zoltan-czigler.jpg";

const LeadershipBoard = [
  {
    id: 1,
    img: [Ruben],
    name: "Dr. Ruben Herrendorff PhD",
    position: "CEO",
    description:
      "Pharmacist and Biotech entrepreneur with preclinical and early clinical drug R&D expertise in autoimmune and neuromuscular diseases. Former CEO & Board member of Polyneuron Pharmaceuticals AG.",
  },
  {
    id: 2,
    img: [Rajesh],
    name: "Dr. med. Rajesh Jayachandran, MD, PhD ",
    position: "Co-founder and CSO ",
    description:
      "Clinician and Immunologist. Swiss Transplantation Society award, Propelling award, Max Cloëtta fellowship, Pfizer award, Perumal award, SNF fellowship. Novartis biotechnology leadership camp.",
  },
  {
    id: 3,
    img: [Zoltan],
    name: "Zoltan Czigler",
    position: "CFO",
    description:
      "Held CFO positions with various technology-based start-ups in Switzerland, where he closed several financing rounds with investors as well as M&A and licensing transactions with corporate partners. He holds an MSc in Engineering Mathematics from the Technical University of Budapest and an MBA from INSEAD. ",
  },
];

export default class Team extends React.Component {
  render() {
    return (
      <React.Fragment>
        {LeadershipBoard.map((leader, index) => (
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            data-index={index}
          >
            <div className="list leadership">
              <div className="card ">
                <div className="image">
                  <img src={leader.img} alt={`${leader.name}`} />
                </div>
                <div className="details">
                  <h3>{leader.name}</h3>
                  <div className="position">{leader.position}</div>
                  <p>{leader.description}</p>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}
